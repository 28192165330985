// Sidebar
$sidebarFontFirstColor: #000;
$sidebarFontSecondColor: #000;
$sidebarBackgroundFirstColor: white;
$sidebarBackgroundSecondColor: #96273a;
$sidebarActiveLinkBackground: #96273a;
$sidebarRightBorderColor: #00263b;

// Header
$headerBackgroundColor: #96273a;
$headerFontColor: white;

// Main Colors
$blueLight: #9bd3e4;
$grayLight: #f5f5ff;
$mainBackgroundColor: #f5f5ff;
$mainFontColor: #93db8f;
$secondaryFontColor: #ff5455;
$thirdFontColor: #ef9c40;

// Popups
$popupTitleColor: #1e1e2d;
$popupTitleFontColor: #a2a3b7;

:root {
  --success-color: #b1dcaf;
  --success-color-dark: #8cb48a;
  --primary-dark-color: #569d51;
  --gray-light-color: #d8d8d8;
  --gray-medium-color: #9b9b9b;
  --header-font-color: #ffffff;
  --primary-color: #96273a;
  --primary-color-tint: #ff99aa;
  --danger-color: #ff5455;
}
